import { render, staticRenderFns } from "./qrcode-box.vue?vue&type=template&id=29c15473&"
import script from "./qrcode-box.vue?vue&type=script&lang=js&"
export * from "./qrcode-box.vue?vue&type=script&lang=js&"
import style0 from "./qrcode-box.vue?vue&type=style&index=0&id=29c15473&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSvg: require('/builds/wj/fe/wenjuan-homesite/components/common/svg.vue').default})
