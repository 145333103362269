
export default {
  name: 'ProgressLine',
  props: {
    width: {
      type: Number,
      default() {
        return 400
      },
    },
    percentage: {
      type: Number,
      default() {
        return 50
      },
    },
  },
}
