
  export default {
    name: "QrcodeBox",
    props: {
      boxSize: {
        type: Number,
        default() {
          return 120
        }
      }
    },
    data() {
      return {
        qrcodeSrc: require("assets/image/app/qrcode.png"),
      }
    },
    computed: {
      cssVarConfig() {
        return {
          "--box-size": `${this.boxSize}px`
        }
      }
    }
  }
