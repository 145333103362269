import { render, staticRenderFns } from "./stars.vue?vue&type=template&id=8e83ab1a&"
import script from "./stars.vue?vue&type=script&lang=js&"
export * from "./stars.vue?vue&type=script&lang=js&"
import style0 from "./stars.vue?vue&type=style&index=0&id=8e83ab1a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSvg: require('/builds/wj/fe/wenjuan-homesite/components/common/svg.vue').default})
