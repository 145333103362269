
import { mapState } from 'vuex'
import Stars from '@/components/app/comments/lib/stars.vue'
import ProgressLine from '@/components/app/comments/lib/progress.vue'
import AppCommentCard from '@/components/app/comments/lib/comment-card.vue'
export default {
  name: 'AppComments',
  components: {
    Stars,
    ProgressLine,
    AppCommentCard,
  },
  props: {
    sourceData: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      rateLineNum: 5,
      moveStep: 512,
    }
  },
  computed: {
    ...mapState(['clientWidth', 'isMobile']),
    commentsMarginLeft() {
      if (this.isMobileTrue) {
        return '24px'
      } else if (this.clientWidth <= 1366) {
        return '42px'
      } else {
        return '137px'
      }
    },
    isMobileTrue() {
      return this.isMobile && this.clientWidth < 1024
    },
  },
  methods: {},
}
