
  import Stars from "@/components/app/comments/lib/stars.vue";
  export default {
    name: "AppCommentCard",
    components: {
      Stars
    },
    props: {
      sourceData: {
        type: Object,
        default() {
          return null
        }
      },
      isMobile: {
        type: Boolean,
        default() {
          return false
        }
      }
    }
  }
