
import { mapState, mapMutations } from 'vuex'
import QrcodeBox from '../../components/app/qrcode-box.vue'
import AppComments from '../../components/app/comments/index.vue'
import AppContentItem from '../../components/app/content-item.vue'
import AppScreenAnimation from '../../components/app/screen-animation.vue'
import { distinguishUserAgentUtils } from '@/utils/tools'
import { getSceneType } from '~/api/library-center/common.js'

export default {
  name: 'App',
  components: {
    QrcodeBox,
    AppComments,
    AppContentItem,
    AppScreenAnimation,
  },
  // layout: 'scence-single',
  data() {
    return {
      transparent: false,
      isClient: false,
      nextUrl: '/app/',
      pageData: Object.freeze({
        top: {
          title: ['问卷网APP', '你的随身问卷助手'],
          subTitle: '高效·便捷·易用',
        },
        second: {
          title: 'App Store 高分应用',
          subTitle: '16,648位用户评分',
          score: '4.9',
          commentList: [
            {
              title: '新的版本很好用',
              user: '90后壹百度',
              star: 5,
              content: '这个版本增加了题型排序和题目复制，个人感觉大大方便了问卷编辑',
            },
            {
              title: '实用的问卷调查平台工具',
              user: 'Benibear',
              star: 5,
              content: '从刚开始不会用，到现在的驾轻就熟，真的越来越喜欢这个平台，很棒，相信我',
            },
            {
              title: '简单实用',
              user: 'katharineloveu',
              star: 5,
              content: '感谢设计者老师们，真心感到很简单实用',
            },
            {
              title: '问卷网——良心软件',
              user: '小小桃姬',
              star: 5,
              content: '真的超爱这个软件，太方便啦',
            },
            {
              title: '调查方便快捷',
              user: '陌蓝',
              star: 5,
              content: '我经常用问卷网进行调查，发现非常好用',
            },
            {
              title: '还是挺好用的',
              user: '使者1111108156',
              star: 5,
              content: '朋友推荐用的，用起来比较顺手，题型符合我的需求',
            },
          ],
        },
        itemList: [
          {
            title: '海量模板',
            subTitle: '创建问卷轻轻松松',
            desc: ['专题模板、场景模板、行业模板，总能找到合适你的模板'],
            imgSrc: require('~/assets/image/app/item-1.png'),
            imgSize: [621, 444],
            mdImgSize: [442, 316],
            mobileImgSize: [327, 234],
            layout: 'row',
          },
          {
            title: '问卷编辑',
            subTitle: '问卷设计不再困难',
            desc: ['题型题库、逻辑设置、外观设置、红包......', '总能实现你所想'],
            imgSrc: require('~/assets/image/app/item-2.png'),
            imgSize: [585, 444],
            mdImgSize: [416, 316],
            mobileImgSize: [327, 248],
            layout: 'row-reverse',
          },
          {
            title: '数据报表',
            subTitle: '随时随地管理你的数据',
            desc: ['统计报表、数据详情、数据下载与分享......', '为你提供优质的数据管理和分析体验'],
            imgSrc: require('~/assets/image/app/item-3.png'),
            imgSize: [619, 481],
            mdImgSize: [440, 342],
            mobileImgSize: [327, 254],
            layout: 'row',
          },
          {
            title: '支持多场景',
            subTitle: '不仅仅是问卷',
            desc: ['问卷调查、考试测评、满意度调研、报名表单、投票评选我们都支持'],
            imgSrc: require('~/assets/image/app/item-4.png'),
            imgSize: [620, 478],
            mdImgSize: [440, 340],
            mobileImgSize: [327, 252],
            layout: 'row-reverse',
          },
        ],
        bottom: {
          title: ['问卷网APP', '你的随身问卷助手'],
          subTitle: '高效·便捷·易用',
        },
      }),
    }
  },
  fetchOnServer: false,
  fetch() {
    this.$store.commit('setHightLightHeader', {
      transparent: true,
    })
  },
  head: {
    title: '问卷网APP-问卷调查设计-问卷调研分析报告APP',
    link: [{ rel: 'canonical', href: 'https://www.wenjuan.com/app/' }],
    meta: [
      {
        hid: 'keywords',
        name: 'keywords',
        content: '问卷调查,调查问卷,满意度调查,市场调查,报名登记表,问卷网',
      },
      {
        hid: 'description',
        name: 'description',
        content:
          '问卷调查网APP，随时随地创建、修改、设置问卷报告，实时查看分析数据，一键分发调查问卷数据，快速分享到微信、QQ、微博等。支持iPhone、Android扫码下载。',
      },
    ],
  },
  computed: {
    ...mapState(['isMobile', 'innerWidth', 'loginStatus', 'headerActive', 'mediaType']),
    isMobileTrue() {
      return this.isMobile && this.innerWidth < 1024
    },
  },
  mounted() {
    this.isClient = true
    getSceneType(this)
    this.setRegisterConfig()
    // 针对场景页的静态化构建，需要每次刷新页面，重新执行cookie和ua的获取
    this.$store.commit('cookie/parse', document.cookie)
    this.judgeUA()
  },
  methods: {
    ...mapMutations(['setHightLightHeader']),
    setRegisterConfig() {
      this.$store.commit('setLoginStatus', {
        nexturl: this.nextUrl,
      })
    },
    downloadApp() {
      const _hmt = window._hmt || []
      const { isIos, isAndroid } = distinguishUserAgentUtils()
      if (isAndroid) {
        _hmt.push(['_trackEvent', 'app_download', 'click', '介绍页APP下载-安卓-首屏'])
      } else if (isIos) {
        _hmt.push(['_trackEvent', 'app_download', 'click', '介绍页APP下载-ios-首屏'])
      }
      window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.idiaoyan.wenjuanwrap')
    },
    judgeUA() {
      const ua = window.navigator.userAgent
      const isMobile = /(iPad|iPhone|iPod|Opera Mini|Android.*Mobile|NetFront|PSP|BlackBerry|Windows Phone)/gi.test(ua)
      const obj = {
        isEdge: ua.includes('Edge'),
        isFF: ua.includes('Firefox'),
        isOpera: ua.includes('Opera'),
        isBB: ua.includes('BlackBerry'),
        isChrome: ua.includes('Chrome'),
        isMaxthon: ua.includes('Maxthon'),
        isFeishu: ua.includes('Lark/'),
        isIos: /(iPhone|iPad|iPod|iOS)/i.test(ua),
        isSafari: ua.includes('Safari') && !ua.includes('Chrome'),
        isMobile,
        isPC: !isMobile,
        isWeixin: ua.toLowerCase().match(/MicroMessenger/i) == 'micromessenger',
        isAlipay: ua.toLowerCase().match(/Alipay/i) == 'alipay',
        isWeibo: ua.toLowerCase().match(/WeiBo/i) == 'weibo',
        isWxwork: ua.toLowerCase().match(/wxwork/i) == 'wxwork',
        isQQ: ua.toLowerCase().match(/QQ/i) == 'qq',
        isAndroidApp: ua.toLowerCase() == 'android_app/1.0.0',
        isAndroid:
          (ua.toLowerCase().match(/android/i) == 'android' || ua.toLowerCase().match(/adr/i) == 'adr') &&
          ua.toLowerCase() != 'android_app/1.0.0',
        isCloudPay: ua.includes('unionpay'),
        // ios android 客户端
        isNative:
          ua.toLowerCase().match(/android_app\//i) == 'android_app/' ||
          ua.toLowerCase().match(/wenjuan\//i) == 'wenjuan/',
        // 判断是否鸿蒙fa卡片
        isHarmony: ua.toLowerCase().match(/harmony_wenjuanwrap/i) == 'harmony_wenjuanwrap',
        // 是否钉钉应用打开
        isDingTalk: ua.toLowerCase().match(/DingTalk/i) == 'dingtalk',
        // 是否腾讯会议
        isTencentWemeet: ua.toLowerCase().match(/tencent_wemeet/i) == 'tencent_wemeet',
        isBaiying: ua.toLowerCase().match(/Baiying/i) == 'baiying',
        isMac: /macintosh|mac os x/i.test(ua),
        isBaidu: ua.toLowerCase().includes('baiduboxapp'),
        isPad: ua.toLowerCase().includes('ipad'),
      }

      this.$store.commit('setMediaType', {
        userAgent: ua,
        ...obj,
      })
    },
  },
}
