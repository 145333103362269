
import { mapState } from 'vuex'

export default {
  props: {
    sourceData: {
      type: Object,
      default() {
        return null
      },
    },
  },
  computed: {
    ...mapState(['clientWidth', 'isMobile']),
    imgSizeType() {
      if (this.isMobileTrue) {
        return 'mobileImgSize'
      } else if (this.clientWidth <= 1366) {
        return 'mdImgSize'
      } else {
        return 'imgSize'
      }
    },
    isMobileTrue() {
      return this.isMobile && this.clientWidth && this.clientWidth < 1024;
    }
  },
}
