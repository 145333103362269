
export default {
  name: 'CommonStars',
  props: {
    num: {
      type: Number,
      default() {
        return 5
      },
    },
    starSize: {
      type: Number,
      default() {
        return 14
      },
    },
    starGap: {
      type: Number,
      default() {
        return 4
      },
    },
  },
  computed: {
    styleConfig() {
      return {
        '--box-size': `${this.starSize}px`,
        '--star-size': `${this.starSize - 2}px`,
        '--star-gap': `${this.starGap}px`,
      }
    },
  },
}
